import React, { Component } from "react";
import { Link } from "react-router-dom";
import smoothscroll from 'smoothscroll-polyfill';

import { Up } from "./Icons";

import "./Footer.css";

interface FooterState {
    showBackToTop: boolean
}

export default class Footer extends Component<{}, FooterState> {
    boundHandleScroll!: () => any;

    constructor(props: {}) {
        super(props);
        this.state = {
            showBackToTop: false
        };
    }

    handleScroll() {
        this.setState({
            showBackToTop: window.pageYOffset > 350
        });
    }

    shouldComponentUpdate(nextProps: {}, nextState: FooterState) {
        return nextState.showBackToTop !== this.state.showBackToTop;
    }

    componentDidMount() {
        smoothscroll.polyfill();
        this.boundHandleScroll = this.handleScroll.bind(this);
        window.addEventListener("scroll", this.boundHandleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.boundHandleScroll);
    }

    render() {
        return (
            <div className="Footer">
                <div
                    className="Footer-backToTop"
                    onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
                    style={{ opacity: this.state.showBackToTop ? 1 : 0 }}
                >
                    <img src={Up} alt="back to top" />
                </div>
                <Link className="Footer-info" to="/attributions">
                    site built & designed by Avi
        </Link>
            </div>
        );
    }
}
