import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Header.css";

class Header extends Component {
    render() {
        return (
            <div className="Header">
                <div className="Header-name">
                    <Link to="/">Avi Romanoff</Link>
                </div>
                <div className="Header-links">{this.props.children}</div>
            </div>
        );
    }
}

export default Header;
