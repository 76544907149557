import React, { Component } from "react";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";

import './App.css';

import Header from './Header'
// import About from './About'
import Projects from './Projects'
import Attributions from './Attributions'
import PageNotFound from './PageNotFound'

import * as AllProjects from "./AllProjects";
import * as Icons from "./Icons";

//@ts-ignore
const projects = Object.keys(AllProjects).map(key => AllProjects[key]);

//@ts-ignore
const icons = Object.keys(Icons).map(key => Icons[key]);

// polyfill for Array.from (for iOS/Safari 8)
if (!Array.from) {
  Array.from = (object: object) => [].slice.call(object);
}

// takes a component instance (e.g. a <Writeup/>) and returns a Component class
const makeComponent = (obj: JSX.Element) =>
  class Page extends Component {
    render() {
      return obj;
    }
  };

interface AppState {
  isTypekitLoading: boolean,
  areImagesLoading: boolean,
  didTypekitFailToLoad: boolean,
}

class App extends Component<{}, AppState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isTypekitLoading: true,
      areImagesLoading: true,
      didTypekitFailToLoad: false
    };
  }

  preloadTimelineIcons() {
    let loadedCount = 0;
    return new Promise((resolve) => {
      icons.forEach(icon => {
        const img = new Image();
        img.src = icon;
        img.onload = () => {
          if (++loadedCount === icons.length) {
            resolve(null);
          }
        };
      });
    });
  }

  componentDidMount() {
    if (!window.hasOwnProperty("Typekit")) {
      this.setState({
        didTypekitFailToLoad: true,
        isTypekitLoading: false
      });
    } else {
      //@ts-ignore
      window.Typekit.load({
        classes: false,
        timeout: 3000,
        active: () => this.setState({ isTypekitLoading: false }),
        inactive: () => this.setState({
          isTypekitLoading: false,
          didTypekitFailToLoad: true
        }),
      });
    }
    this.preloadTimelineIcons().then(
      () => this.setState({ areImagesLoading: false })
    );
  }

  render() {
    const isLoading =
      this.state.isTypekitLoading || this.state.areImagesLoading;
    return (
      <div className="App" style={{ opacity: isLoading ? 0 : 1 }}>
        {!this.state.didTypekitFailToLoad ? null : (
          <div className="App-banner" onClick={() => window.location.reload()}>
            <img src={Icons.Warning} alt="warning" />
            <div className="App-banner-text">
              <strong>Heads up.</strong> There was a problem loading the fonts
              on this website. Text will be formatted incorrectly. Tap here to
              try again.
            </div>
          </div>
        )}
        <Header>
          {/* <NavLink to="/about" activeClassName="active" exact>
            about
            </NavLink> */}
          <NavLink to="/projects" activeClassName="active">
            projects
          </NavLink>
          <a href="/resume.pdf">résumé</a>
        </Header>
        <Switch>
          {/* <Route exact path="/about" component={About} /> */}
          {projects.map((project, i) => {
            const { writeup, slug } = project;
            const writeupComponent = makeComponent(
              React.cloneElement(writeup, {
                prevProject: i > 0 ? projects[i - 1] : null,
                nextProject: i < projects.length - 1 ? projects[i + 1] : null
              })
            );
            return (
              <Route
                path={`/projects/${slug}`}
                key={slug}
                component={writeupComponent}
              />
            );
          })}
          <Route path="/projects" component={Projects} />
          <Route path="/attributions" component={Attributions} />
          <Redirect from="/" to="/projects" exact />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}

export default App