import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './grub-hero/thumbnail.png'
import Screenshot1 from './grub-hero/grubhero-screenshot-1.png'
import Screenshot2 from './grub-hero/grubhero-screenshot-2.png'
import Screenshot3 from './grub-hero/grubhero-screenshot-3.png'
import Screenshot4 from './grub-hero/grubhero-screenshot-4.png'
import Screenshot5 from './grub-hero/grubhero-screenshot-5.png'

export default class GrubHero {

  static slug = 'grub-hero'
  static title = 'GrubHero'

  static writeup = (
    <ProjectWriteup
      title={GrubHero.title}
      links={[
        ['http://devpost.com/software/grubhero', 'DevPost submission'],
        ['https://github.com/aroman/grubhero', 'source code (GitHub)'],
      ]}
      team="Drew Gottlieb">
      <p>
        Built in 48 hours as part of the Fall 2013 PennApps Hackathon at The University of Pennsylvania.
        My partner and I created a platform for facilitating and coordinating group meal ordering — including payments.
        User accounts, and financial transcations are handled via Venmo, the leading platform for mobile social payments.
        Features include Facebook integration, real-time email notifications, a personalized activity feed, and powerful menu online menu creation tools.
      </p>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery nudgeUp images={[Screenshot1]} caption="project homepage" />
      <Gallery nudgeUp images={[Screenshot2]} caption="signing in with a Venmo account" />
      <Gallery nudgeUp images={[Screenshot3]} caption="logged in homepage" />
      <Gallery nudgeUp images={[Screenshot4]} caption="organizing a group meal as a Grub Hero" />
      <Gallery nudgeUp images={[Screenshot5]} caption="participating in a group meal order" />
      <div className="ProjectWriteup-subtitle">Implementation</div>
      <p>
        <em>coming soon</em>
      </p>
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={GrubHero.title} slug={GrubHero.slug} thumbnail={Thumbnail}>
      <p>Group meals have never been this easy.</p>
      <p>Create a menu for your friends in 1 minute. Collect their payments painlessly with Venmo.</p>
    </ProjectPreview>
  )

}
