import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'

import Thumbnail from './aviromanoff-me/thumbnail.png'

export default class AviRomanoffDotMe {

  static slug = 'aviromanoff-me'
  static title = 'aviromanoff.me'

  static writeup = (
    <ProjectWriteup title={AviRomanoffDotMe.title}
      links={[['http://aviromanoff.me?dont-redirect', 'aviromanoff.me (offline)']]}>
      <p>
        The site was entirely statically-generated (with <a href="http://wintersmith.io/">Withsmith</a>) and was my little corner of the internet from 2013-2016. You can find the code online at <a href="https://GitHub.com/aroman/aviromanoff.me">GitHub.com/aroman/aviromanoff.me</a>.
      </p>
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={AviRomanoffDotMe.title} slug={AviRomanoffDotMe.slug} thumbnail={Thumbnail}>
      <p>My previous website</p>
      <p>Sort of like this website, but a lot older and simpler. It had some unique content, though, like poetry!. I've taken the site down to preserve my privacy.</p>
    </ProjectPreview>
  )

}
