import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './fuji/thumbnail.png'
import Screenshot1 from './fuji/fuji-screenshot-1.png'
import Screenshot2 from './fuji/fuji-screenshot-2.png'
import Screenshot3 from './fuji/fuji-screenshot-3.png'
import TeamPic from './fuji/fuji-team.jpg'

export default class Fuji {

  static slug = 'fuji'
  static title = 'Fuji'

  static writeup = (
    <ProjectWriteup
      title={Fuji.title}
      trophy="Grand Prize, Best User Experience awards at PennApps X"
      links={[
        ['http://www.thedp.com/article/2014/09/pennapps-winner', 'Press: The Daily Pennsylvanian'],
      ]}
      team="Zach Latta, Joe Doyle, Maggie Bignell">
      <p>
        Fuji is like Xcode in your browser. It&lsquo;s a fully functional web IDE for developing iOS apps in your browser.
        You can log in with GitHub, immediately edit and commit to your projects, and view your work instantly in an iPhone simulator directly in your browser.
      </p>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery
        images={[Screenshot1]}
        caption="editing, building, and running an iPhone app in Chrome"
      />
      <Gallery
        images={[Screenshot2]}
        caption="cloning a new project from GitHub"
      />
      <Gallery
        images={[Screenshot3]}
        caption="logo + branding"
      />
      <Gallery
        hasBorder
        images={[TeamPic]}
        caption="the Fuji team + PennApps friends"
      />
      <div className="ProjectWriteup-subtitle">Architecture</div>
      <p>
        <em>coming soon</em>
      </p>
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={Fuji.title} slug={Fuji.slug} thumbnail={Thumbnail} hasTrophy>
      <p>Develop and run iOS apps in your browser.</p>
      <p>Compile, test, and run any iPhone app quickly and easily, even on Windows laptops and Android tablets.</p>
    </ProjectPreview>
  )

}
