// @flow weak
import React, { Component } from "react";

import "./Attributions.css";

class Attribution extends Component<{ name: string, author: string }> {
    render() {
        const { name, author } = this.props;
        return (
            <div className="Attribution">
                <span className="Attribution-name">&ldquo;{name}&rdquo;</span>
                <span> by </span>
                <span className="Attribution-author">{author}</span>
            </div>
        );
    }
}

export default class Attributions extends Component {
    render() {
        return (
            <div className="Attributions">
                <div className="Attributions-container">
                    <div className="Attributions-subtitle">About this website</div>
                    <div className="Attributions-text">
                        I built and designed this website &ldquo;from scratch&rdquo;.<br />
            It was programmed in{" "}
                        <a href="https://en.wikipedia.org/wiki/JavaScript">JavaScript</a>,
            built with <a href="https://reactjs.org/">React</a>, and hosted on{" "}
                        <a href="https://www.netlify.com/">Netlify</a>. It was designed with
            paper, pencil, and <a href="https://www.sketchapp.com/">Sketch</a>.
                    </div>
                    <div className="Attributions-subtitle">Icon Attributions</div>
                    <Attribution name="Brain" author="David" />
                    <Attribution name="Atom" author="Erin" />
                    <Attribution name="To Top" author="Jeremy Elder" />
                    <Attribution name="Group" author="Timothy Miller" />
                    <Attribution name="Link" author="Bohdan Burmich" />
                    <Attribution name="Suitcase" author="Aaron K. Kim" />
                    <Attribution name="Trophy" author="Thomas Helbig" />
                    <Attribution name="Information" author="Tobias F. Wolf" />
                    <Attribution name="Birthday" author="Mark McCormick" />
                    <div className="Attributions-text">
                        Thanks to the above individuals for allowing me to use their icons
            from <a href="https://thenounproject.com">The Noun Project</a>.
                    </div>
                </div>
            </div>
        );
    }
}
