import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './eden/thumbnail.png'
import Screenshot1 from './eden/screenshot-1.png'


export default class Eden {

  static slug = 'eden'
  static title = 'Eden'

  static writeup = (
    <ProjectWriteup
      title={Eden.title}
      trophy={"Linode's \"Best Cloud Hack\" prize at PennApps Winter 2015"}
      links={[
        ['http://devpost.com/software/eden', 'DevPost submission'],
        ['https://github.com/zachlatta/eden', 'source code (GitHub)'],
      ]}
      team="Zach Latta">
      <p>
        We built a platform that lets you to break free from Apple's walled garden. With Eden, you can deploy a personal OS X instance on Amazon EC2, Linode, or any other cloud provider in minutes, and use it to securely relay iMessage to any platform &mdash; including Android, Windows Phone, and the Web. Eden is made possible by the tremendous efforts of the hackintosh and emulation communities to virtualize OS X and run it on non-Apple hardware. By standing on the shoulders of giants, Eden enables you to climb out of the locked-down Apple ecosystem and access your services and data on your terms, on any platform.
      </p>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery images={[Screenshot1]} />
      <div className="ProjectWriteup-subtitle">Architecture</div>
      <p>
        <em>coming soon</em>
      </p>
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={Eden.title} slug={Eden.slug} thumbnail={Thumbnail} hasTrophy>
      <p>Virtual macOS & iMessage in the cloud.</p>
      <p>Deploy a personal macOS cloud in minutes. Relay iMessage anywhere — including Android and the Web.</p>
    </ProjectPreview>
  )

}
