import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './popsicle/thumbnail.png'
import Screenshot1 from './popsicle/popsicle-screenshot-1.png'
import Screenshot2 from './popsicle/popsicle-screenshot-2.png'
import Screenshot3 from './popsicle/popsicle-screenshot-3.png'
import Screenshot4 from './popsicle/popsicle-screenshot-4.png'


export default class Popsicle {

  static slug = 'popsicle'
  static title = 'Popsicle'

  static writeup = (
    <ProjectWriteup
      title={Popsicle.title}
      trophy="1st Prize at Apple's Swift Hackathon (Carnegie Mellon)"
      links={[
        ['http://devpost.com/software/popsicle', 'DevPost Submission'],
      ]}
      team="Ilter Canberk, Mirai Akagawa, Takumi Fujimoto">
      <div className="ProjectWriteup-subtitle">Share your friend's internet</div>
      <p>
        What if you could browse the Internet in Airplane mode? Through the power of sharing, caring, and iOS 8 — you can. Open any website in Safari, tap the "Save with Popsicle" button, and the entire website is saved for offline browsing — including links, images, and styles. You can even click links on the page and browse them offline as well, using our intelligent spidering algorithm.
      </p>
      <div className="ProjectWriteup-subtitle">Even if you're both offline</div>
      <p>
        Stuck on a plane without a recent copy of the New York Times? Someone on board probably has your back. Popsicle can connect via Bluebooth or peer-to-peer WiFi to transfer caches between users. Just tap on the website you want to download from another popsicle user, and in a matter of seconds you'll be reading the latest edition.
      </p>
      <Gallery images={[Screenshot1]} caption="Popsicle Safari Extension" />
      <Gallery images={[Screenshot2]} caption="our iOS App showing content available over ad-hoc intranet" />
      <Gallery images={[Screenshot3]} caption="receiving an offline copy of a website over Bluetooth" />
      <Gallery images={[Screenshot4]} caption="iOS app now shows the recently downloaded copy" />
      <div className="ProjectWriteup-subtitle">Architecture</div>
      <p>
        <em>coming soon</em>
      </p>
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={Popsicle.title} slug={Popsicle.slug} thumbnail={Thumbnail} hasTrophy>
      <p>Share the Internet — offline.</p>
      <p>Save any website in Safari. Share it peer-to-peer with friends who can't get online.</p>
    </ProjectPreview>
  )

}
