import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './lingua/thumbnail.png'
import Screenshot1 from './lingua/lingua-screenshot-1.png'
import Screenshot2 from './lingua/lingua-screenshot-2.png'
import Screenshot3 from './lingua/lingua-screenshot-3.png'
import Screenshot4 from './lingua/lingua-screenshot-4.png'


export default class Lingua {

  static slug = 'lingua'
  static title = 'Lingua'

  static writeup = (
    <ProjectWriteup title={Lingua.title}>
      <p style={{ marginTop: '-10px' }}>
        In my 9th grade Latin class, my friends and I (and Latin students around the world) struggled with a lack of modern (technologically speaking), easy-to-use, and fast digital Latin dictionaries. As a requirement of my Latin course, I maintained a list of all of the Latin vocabulary that I learned in class on a Word document. To help myself and my classmates, I created a website and API that allows anyone to search my Latin dictionary Word document, which I parsed and stored in a relational database. The website searches using a clean and elegant minimalistic interface with real-time, as-you-type, results. It can search in either Latin or English.
      </p>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery nudgeUp images={[Screenshot1]} caption="project homepage" />
      <Gallery nudgeUp images={[Screenshot2]} caption="searching for vocabulary (Latin to English)" />
      <Gallery nudgeUp images={[Screenshot3]} caption="vocaulary search results (English to Latin)" />
      <Gallery nudgeUp images={[Screenshot4]} caption="vocaulary search results (Latin to English)" />
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={Lingua.title} slug={Lingua.slug} thumbnail={Thumbnail}>
      <p>Latin-to-English Dictionary</p>
      <p>Upload Latin-to-English vocabulary lists. Search in either language and see definitions as you type.</p>
    </ProjectPreview>
  )

}
