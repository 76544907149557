import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./ProjectPreview.css";

import { New } from "./Icons";

type ProjectPreviewProps = {
    title: string,
    thumbnail: string,
    hasTrophy: boolean,
    isNew: boolean,
    slug: string,
    children: any,
}

class ProjectPreview extends Component<ProjectPreviewProps> {
    render() {
        const { title, thumbnail, isNew, slug, children } = this.props;
        const projectUrl = `/projects/${slug}`;
        return (
            <Link className="ProjectPreview" to={projectUrl}>
                <img
                    className="ProjectPreview-thumbnail"
                    src={thumbnail}
                    alt="thumbnail"
                />
                <div className="ProjectPreview-info-container">
                    <div className="ProjectPreview-title">
                        {/* XXX */}
                        {slug === "too-many-captains" ? (
                            <div>
                                Too Many Captains<br />and Not Enough Wire
                            </div>
                        ) : (
                                <div>{title}</div>
                            )}

                        {/* {hasTrophy && (
              <img
                className="ProjectPreview-trophy"
                src={Trophy}
                alt="won award"
              />
            )} */}
                        {isNew && (
                            <img className="ProjectPreview-new" src={New} alt="new project" />
                        )}
                    </div>
                    <div className="ProjectPreview-description">{children}</div>
                    <Link className="ProjectPreview-link" to={projectUrl}>
                        learn more
                    </Link>
                </div>
            </Link>
        );
    }
}

export default ProjectPreview;
