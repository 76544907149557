// @flow weak
import React, { Component } from "react";

import "./PageNotFound.css";

class PageNotFound extends Component {
    render() {
        return (
            <div className="PageNotFound">
                <div className="PageNotFound-text">page not found</div>
                <iframe
                    title="spongebob clip"
                    src="https://www.youtube.com/embed/tZQJhvs4amQ?rel=0&amp;controls=0&amp;showinfo=0"
                    frameBorder={0}
                    allowFullScreen
                />
            </div>
        );
    }
}

export default PageNotFound;
