import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './veritas/thumbnail.png'
import Screenshot1 from './veritas/veritas-screenshot-1.png'
import Screenshot2 from './veritas/veritas-screenshot-2.png'
import Screenshot3 from './veritas/veritas-screenshot-3.png'
import Screenshot4 from './veritas/veritas-screenshot-4.png'
import Screenshot5 from './veritas/veritas-screenshot-5.png'
import Screenshot6 from './veritas/veritas-screenshot-6.png'
import Screenshot7 from './veritas/veritas-screenshot-7.png'

export default class Veritas {

  static slug = 'veritas'
  static title = 'Veritas'

  static writeup = (
    <ProjectWriteup
      title={Veritas.title}
      links={[
        ['http://veritas-me.com', 'veritas-me.com'],
        ['https://github.com/aroman/veritas', 'source code (GitHub)'],
      ]}>
      <p>
        While studying computer science at the Harvard Summer School as a rising junior, I greatly enjoyed meeting and getting to know new students each day. However, after the first week, I realized that this strategy would not scale. Before too long, the summer would be over, and there would be many students with whom I had not connect. Therefore, I created an informal on-line social network exclusively for other Harvard Summer School students. I wrote a program to scrape the Harvard Summer School website and compile and a database of all of the courses offered that summer. Students signed up with their Harvard IDs, and selected the courses in which they were enrolled, and the dorm in which they lived. The website, which I named Veritas after the motto of Harvard, was composed of various chat rooms for these different groups, as well as a general chat for all students. I posted the link to the Facebook group for the 2012 Harvard Summer School session, and just 24 hours later over 100 had signed up.
      </p>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery nudgeUp images={[Screenshot1]} caption="project homepage" />
      <Gallery nudgeUp images={[Screenshot2]} caption="signing up for a new account" />
      <Gallery nudgeUp images={[Screenshot3]} caption="selecting courses from course catalog" />
      <Gallery nudgeUp images={[Screenshot4]} caption="reviewing selected courses" />
      <Gallery nudgeUp images={[Screenshot5]} caption="signed-in home page" />
      <Gallery nudgeUp images={[Screenshot6]} caption="course chatroom" />
      <Gallery nudgeUp images={[Screenshot7]} caption="user profile page" />
      <div className="ProjectWriteup-subtitle">Architecture</div>
      <p>
        Veritas's IRC-like chatrooms are powered by Socket.IO, which automatically selects the fastest and most reliable realtime transport scheme available for a given client. Veritas uses MongoDB to store dorm, course, profile, and chat data.
      </p>
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={Veritas.title} slug={Veritas.slug} thumbnail={Thumbnail}>
      <p>Harvard Summer School Social Network</p>
      <p>Choose your dorm and courses. Instantly chat and connect with 700 other summer school students.</p>
    </ProjectPreview>
  )

}
