import React from "react";

import ProjectWriteup from "../ProjectWriteup";
import ProjectPreview from "../ProjectPreview";
import Gallery from "../Gallery";

import Thumbnail from "./build-a-beast/thumbnail.png";
import Player1 from "./build-a-beast/player1.jpg";
import Player2 from "./build-a-beast/player2.jpg";
import Screen1 from "./build-a-beast/screen1.jpg";
import Screen2 from "./build-a-beast/screen2.jpg";
import Tiles1 from "./build-a-beast/tiles1.jpg";
import Tiles2 from "./build-a-beast/tiles2.jpg";

export default class BuildABeast {
  static slug = "build-a-beast";
  static title = "Build-a-Beast";

  static writeup = (
    <ProjectWriteup
      title={BuildABeast.title}
      trophy="Highest-scoring game at 2017 CMU Booth Competition"
      links={[
        [
          "https://github.com/AEPi-AK/barnyard-display",
          "game UI (source code)"
        ],
        [
          "https://github.com/AEPi-AK/barnyard-server",
          "game server (source code)"
        ],
        [
          "https://github.com/AEPi-AK/barnyard-light",
          "LED lighting (source code)"
        ],
        [
          "https://github.com/AEPi-AK/barnyard-tile-station",
          "RFID reader (source code)"
        ],
        [
          "https://github.com/AEPi-AK/barnyard-console",
          "admin console (source code)"
        ]
      ]}
      team="Alex Baikovitz, Jordan Brown, Caroline Hermans, Aaron Meyers, Everi Osofsky, Zach Sussman"
    >
      <p>
        Played by hundreds of people of all ages at Carnegie Mellon's{" "}
        <a href="https://www.springcarnival.org/">Spring Carnival</a>.
      </p>
      <p>
        Build-A-Beast is a game about survival of the fittest. Players compete
        to assemble animal body parts into a hybrid, attempting to survive a
        generated environment. Depending on whether they get the Arctic,
        Grasslands, Desert, or Rainforest, players scramble to find the best
        combination for survival.
      </p>
      <div className="ProjectWriteup-subtitle">Gameplay</div>
      <div className="ProjectWriteup-embedContainer">
        <video width="360" height="640" controls>
          <source src=".mp4" type="video/mp4" />
        </video>
        <iframe
          title="Gameplay video"
          src="https://www.youtube.com/embed/7fXFh-JkcZ0?theme=light"
          frameBorder={"0"}
          allowFullScreen
        />
      </div>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery
        images={[Tiles1, Tiles2]}
        caption="RFID-enabled tiles with body parts"
      />
      <Gallery
        images={[Screen1, Screen2]}
        caption="Screen displaying the scores"
      />
      <Gallery images={[Player1, Player2]} caption="Some of the participants" />
      <div className="ProjectWriteup-subtitle">Rules and architecture</div>
      <div className="ProjectWriteup-embedContainer">
        <video width="360" height="640" controls>
          <source src=".mp4" type="video/mp4" />
        </video>
        <iframe
          title="Rules and architecture video"
          src="https://www.youtube.com/embed/fL11ZQzOENg?theme=light"
          frameBorder={"0"}
          allowFullScreen
        />
      </div>
    </ProjectWriteup>
  );

  static preview = (
    <ProjectPreview
      title={BuildABeast.title}
      slug={BuildABeast.slug}
      thumbnail={Thumbnail}
      hasTrophy
    >
      <p>A game about survival of the fittest.</p>
      <p>
        Play God as you build your own animals to survive in the wild. Played by
        hundreds at Spring Carnival 2017.
      </p>
    </ProjectPreview>
  );
}
