import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './bets-on/thumbnail.png'
import Screenshot1 from './bets-on/betson-screenshot-1.png'
import Screenshot2 from './bets-on/betson-screenshot-2.png'
import Screenshot3 from './bets-on/betson-screenshot-3.png'
import Screenshot4 from './bets-on/betson-screenshot-4.png'

export default class BetsOn {

  static slug = 'bets-on'
  static title = 'BetsOn'

  static writeup = (
    <ProjectWriteup
      title={BetsOn.title}
      trophy={"Dropbox's \"It Just Works\" prize at PennApps Spring 2014"}
      links={[
        ['http://devpost.com/software/betson', 'DevPost submission'],
        ['https://github.com/aroman/betson', 'source code (GitHub)'],
      ]}
      team="Russell Kaplan, Jared Zoneraich, Sawyer Altman">
      <p>
        Make programmatically verifiable bets with your friends. Select a custom-built or preset bet on your Pebble and shake hands with your Pebble-wearing friend. Your bet is now sealed: BetsOn shares your bet on your social media and will verify the winning condition programmatically, hooking into Venmo to make sure the loser pays up. Browse our preexisting bets or create your own with an If-This-Then-That-style editor.
      </p>
      <p>
        For exampe, you can bet your friend $5 that the phrase "BetsOn" will on the URL "pennapps.com/winners" by 2/16/14.
      </p>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery nudgeUp images={[Screenshot1]} caption="project homepage" />
      <Gallery nudgeUp images={[Screenshot2]} caption="recommended friends to challenge to a bet" />
      <Gallery nudgeUp images={[Screenshot3]} caption="available bets" />
      <Gallery nudgeUp images={[Screenshot4]} caption="project homepage" />
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={BetsOn.title} slug={BetsOn.slug} thumbnail={Thumbnail} hasTrophy>
      <p>Hold your friends to their bets.</p>
      <p>Choose a bet with your Pebble smartwatch. Money transfers automatically with Venmo.</p>
    </ProjectPreview>
  )

}
