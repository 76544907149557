import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './onreel-news/thumbnail.png'
import Screenshot1 from './onreel-news/grid.png'
import Screenshot2 from './onreel-news/map.jpg'
import TeamPic from './onreel-news/pose.png'

export default class OnreelNews {

  static slug = 'onreel-news'
  static title = 'Onreel.News'

  static writeup = (
    <ProjectWriteup
      title={OnreelNews.title}
      trophy="1st place at Facebook's Global Hackathon Finals"
      links={[
        ['http://onreel.news/', 'onreel.news'],
        ['https://www.facebook.com/hackathon/videos/10153141208170988/', 'facebook.com/hackathon'],
        ['http://www.cmu.edu/news/stories/archives/2015/december/facebook-hackathon.html', 'cmu.edu'],
      ]}
      team="Tiffany Jiang, Nikhil Choudhary, Sumanth Pandugula">
      <p>
        Onreel.News lets users track breaking stories around the world in real time through videos taken by direct witnesses. Clicking on a marker allows the user to watch the latest Instagram videos concerning breaking news in a clear 3x3 grid format.
      </p>
      <div className="ProjectWriteup-subtitle">Demo</div>
      <div className="ProjectWriteup-embedContainer">
        <iframe allowFullScreen={true} src="https://player.vimeo.com/video/146511199?wmode=opaque&amp;api=1" title="OnReel News"></iframe>
      </div>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery hasBorder images={[Screenshot1]} caption="prototype simulating what users would have seen the night of the Paris Attacks" />
      <Gallery hasBorder images={[Screenshot2]} caption="our novel video viewer UI" />
      <div className="ProjectWriteup-subtitle">Reception</div>
      <p>
        We placed first at the Facebook's 5th Annual Global Hackathon Finals out of 78 finalists, 21 teams and 11 countries in total.
      </p>
      <Gallery hasBorder images={[TeamPic]} caption="the Onreel.News team + friends at Facebook HQ" />
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={OnreelNews.title} slug={OnreelNews.slug} thumbnail={Thumbnail} hasTrophy>
      <p>Breaking news meets eyewitness video.</p>
      <p>Watch the latest Instagram videos covering breaking news around the world in an innovative 3x3 grid.</p>
    </ProjectPreview>
  )

}
