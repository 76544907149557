import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './pyyle/thumbnail.png'
import Screenshot1 from './pyyle/pyyle-screenshot-1.png'

export default class Pyyle {

  static slug = 'pyyle'
  static title = 'Pyyle'

  static writeup = (
    <ProjectWriteup
      title={Pyyle.title}
      links={[
        ['http://pyyle.herokuapp.com/', 'pyyle.com'],
        ['https://github.com/aroman/pyyle', 'source code (GitHub)'],
      ]}>
      <p>
        details coming soon
      </p>
      <div className="ProjectWriteup-subtitle">Screenshot</div>
      <Gallery nudgeUp images={[Screenshot1]} caption="adding a research card" />
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={Pyyle.title} slug={Pyyle.slug} thumbnail={Thumbnail}>
      <p>Digital flashcards as intuitive as paper</p>
      <p>Take notes as you research for an essay. Click to automatically generate and print them as a PDF. </p>
    </ProjectPreview>
  )

}
