import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'

import Thumbnail from './hacker-chat/thumbnail.png'


export default class HackerChat {

  static slug = 'hacker-chat'
  static title = 'HackerChat'

  static writeup = (
    <ProjectWriteup
      title={HackerChat.title}
      trophy="1st Place at PilotPhilly 2013"
      links={[
        ['http://hackerchat.im', 'hackerchat.im'],
        ['https://github.com/aroman/hackerchat', 'source code (GitHub)'],
        ['http://lablayers.deviantart.com/journal/My-Weekend-at-PilotPhilly-412888323', 'my teammate\'s blog post'],
      ]}
      team="Victor Lourng">
      <p>
        Real-time chat that can be extended (&ldquo;hacked&rdquo;) in real time using a custom JavaScript API.
        Built-in hacks includes XKCD image display support and full website embed support (via iframes).
        &ldquo;Hacking&rdquo; is done through a complete in-browser code editor, and propogated and executed in realtime to all connected users.
        My partner in creating this project has written up a lengthy blog post chronicling our experience at the hackathon.
      </p>
      <div className="ProjectWriteup-subtitle">Implementation</div>
      <p>
        <em>Coming soon!</em>
      </p>
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={HackerChat.title} slug={HackerChat.slug} thumbnail={Thumbnail} hasTrophy>
      <p>Hackable collaboration from hackers</p>
      <p>Create chat groups and IM your friends. Extend the chat interface with JavaScript in real-time.</p>
    </ProjectPreview>
  )

}
