import React from "react";

import ProjectWriteup from "../ProjectWriteup";
import ProjectPreview from "../ProjectPreview";

import Thumbnail from "./too-many-captains/thumbnail.png";

export default class TooManyCaptains {
  static slug = "too-many-captains";
  static title = "Too Many Captains and Not Enough Wire";

  static writeup = (
    <ProjectWriteup
      title={TooManyCaptains.title}
      trophy="Finalist, IGF 2018 alt.ctrl.GDC Award"
      links={[["http://toomanycaptains.com/", "Official Website"]]}
    >
      <p>
        Only one star ship remains: yours. Unfortunately, you’re missing some
        key crew members… actually, your crew is entirely captains, and one
        engineer. You’ll need need to work together to operate the ship’s
        weapons, shields, and thrusters to survive the dangers of outer space.
        There’s just one problem: the engineer can’t see the screen, so everyone
        will need to talk it out, FAST!
      </p>
      <div className="ProjectWriteup-embedContainer">
        <video width="360" height="640" controls>
          <source src=".mp4" type="video/mp4" />
        </video>
        <iframe
          title="Rules and architecture video"
          src="https://www.youtube.com/embed/swNE4XO259Q?theme=light"
          frameBorder={"0"}
          allowFullScreen
        />
      </div>
    </ProjectWriteup>
  );

  static preview = (
    <ProjectPreview
      title={TooManyCaptains.title}
      slug={TooManyCaptains.slug}
      thumbnail={Thumbnail}
      hasTrophy
    >
      <p>Gnarly new game with a custom controller.</p>
      <p>State your orders. Plug in wires. Try to keep up.</p>
    </ProjectPreview>
  );
}
