import React, { Component } from 'react'

import './Gallery.css'

const imageMargin = 0.5

type GalleryProps = {
    images: Array<any>,
    caption?: string,
    hasBorder?: boolean,
    nudgeUp?: boolean,
    style?: React.CSSProperties,
}

export default class Gallery extends Component<GalleryProps> {

    render() {
        const { images, caption, hasBorder, nudgeUp, style } = this.props
        // nudgeUp is used to move caption closer to the bottom of the image,
        // useful if a drop shadow is part of the image itself
        return (
            <div className="Gallery" style={style}>
                {
                    images.map(image => (
                        <img
                            className={hasBorder ? "Gallery-imageBorder" : ""}
                            src={image}
                            key={image}
                            alt={caption + (images.length > 1 ? '1' : '')}
                            style={{
                                width: `${((100 - (imageMargin * 2 * images.length)) / images.length)}%`,
                                margin: (images.length > 1 ? `${imageMargin}%` : 'unset'),
                            }}
                        />
                    ))
                }
                <div className="Gallery-caption" style={{ marginTop: (nudgeUp ? '-30px' : '-8px') }}>
                    {caption}
                </div>
            </div>
        )
    }

}
