import React from "react";

import ProjectWriteup from "../ProjectWriteup";
import ProjectPreview from "../ProjectPreview";
import Gallery from "../Gallery";

import Thumbnail from "./exile-from-morewood/thumbnail.png";
import Creator1 from "./exile-from-morewood/creator1.png";
import Creator2 from "./exile-from-morewood/creator2.png";
import Creator3 from "./exile-from-morewood/creator3.png";
import Creator4 from "./exile-from-morewood/creator4.png";
import Web1 from "./exile-from-morewood/web1.jpg";
import Web2 from "./exile-from-morewood/web2.jpg";
import Web3 from "./exile-from-morewood/web3.jpg";
import Art1 from "./exile-from-morewood/art1.png";
import Art2 from "./exile-from-morewood/art2.png";
import Art3 from "./exile-from-morewood/art3.png";
import Art4 from "./exile-from-morewood/art4.png";
import Art5 from "./exile-from-morewood/art5.png";
import Art6 from "./exile-from-morewood/art6.png";
import Art7 from "./exile-from-morewood/art7.png";
import Art8 from "./exile-from-morewood/art8.png";
import Art9 from "./exile-from-morewood/art9.png";
import Art10 from "./exile-from-morewood/art10.png";

export default class ExileFromMorewood {
  static slug = "exile-from-morewood";
  static title = "Exile From Morewood";

  static writeup = (
    <ProjectWriteup
      title={ExileFromMorewood.title}
      trophy="3rd Place at 2016 CMU Booth Competition"
      links={[
        ["http://ExileFromMorewood.com/", "ExileFromMorewood.com"],
        [
          "https://github.com/AEPi-AK/ExileFromMorewood.com",
          "website source code"
        ],
        ["https://github.com/AEPi-AK/character-creator", "console source code"],
        ["https://github.com/AEPi-AK/game-server", "game server source code"],
        [
          "https://github.com/AEPi-AK/character-server",
          "character server source code"
        ]
      ]}
      team="Jordan Brown, Caroline Hermans, Ben Lichtman, AEPi Fraternity"
    >
      <p>
        Exile From Morewood was an interactive Dungeons & Dragons project
        created for AEPi’s booth at Carnegie Mellon University for Spring
        Carnival 2016. Players can interactively create a character using their
        school ID card or custom-printed PVC plastic &ldquo;DragonSlayer&rdquo;
        cards, and then battle monsters with solo or cooperative turn-based
        combat. Players can view their progress and edit character details
        online at ExileFromMorewood.com
      </p>
      <p>
        This installation was a large-scale collaboration among brothers of my
        fraternity and the greater Carnegie Mellon community. I was responsible
        for all things digital &mdash; visual design, UX, frontend software
        architecture, building, installing, and networking the Raspberry
        Pi-based IoT fleet.
      </p>
      <Gallery
        hasBorder
        images={[Creator1, Creator2, Creator3, Creator4]}
        caption="the finished project in action"
      />
      <div className="ProjectWriteup-subtitle">Video walkthrough</div>
      <div className="ProjectWriteup-embedContainer">
        <iframe
          title="video walkthrough"
          src="https://www.youtube.com/embed/NU9r4nS4yC0?theme=light"
          frameBorder="0"
          allowFullScreen
        />
      </div>
      <div className="ProjectWriteup-subtitle">UX & visual design</div>
      <Gallery
        hasBorder
        images={[Web1, Web2, Web3]}
        caption="ExileFromMorewood.com"
      />
      <Gallery hasBorder images={[Art1]} caption="kiosk greeting screen" />
      <Gallery
        hasBorder
        images={[Art2]}
        caption="prompting user to tap their ID card"
      />
      <Gallery hasBorder images={[Art3]} caption="creating a new character" />
      <Gallery hasBorder images={[Art4]} caption="choosing a race" />
      <Gallery
        hasBorder
        images={[Art5]}
        caption="dice animate to simulate rolling"
      />
      <Gallery
        hasBorder
        images={[Art6]}
        caption="newly created character with unique player number"
      />
      <Gallery
        hasBorder
        images={[Art7]}
        caption="kiosk offboarding instructions"
      />
      <Gallery
        hasBorder
        images={[Art8]}
        caption="experienced players can tap a DragonSlayer card (custom-printed PVC plastic card) to upgrade their account"
      />
      <Gallery
        hasBorder
        images={[Art9]}
        caption="player choosing an attack to battle a Hill Giant"
      />
      <Gallery
        hasBorder
        images={[Art10]}
        caption="two players attacking a Hill Giant in turn-based cooperative multiplayer combat"
      />
    </ProjectWriteup>
  );

  static preview = (
    <ProjectPreview
      title={ExileFromMorewood.title}
      slug={ExileFromMorewood.slug}
      thumbnail={Thumbnail}
      hasTrophy
    >
      <p>Dungeons & Dragons themed smart-house.</p>
      <p>
        Tap your ID card to save your progress and compete in online
        leaderboard. Built for Spring Carnival 2016.
      </p>
    </ProjectPreview>
  );
}
