import React from "react";

import ProjectWriteup from "../ProjectWriteup";
import ProjectPreview from "../ProjectPreview";
import Gallery from "../Gallery";

import Thumbnail from "./murder-ballad/thumbnail.png";
import ShirtFront from "./murder-ballad/shirt-front.png";
import ShirtBack from "./murder-ballad/shirt-back.png";
import Snapchat1 from "./murder-ballad/snapchat-1.jpg";
import Snapchat2 from "./murder-ballad/snapchat-2.png";
import TeaserA from "./murder-ballad/teaser-a.png";
import TeaserB from "./murder-ballad/teaser-b.png";
import Poster from "./murder-ballad/poster.png";

import Bodies1 from "./murder-ballad/process/bodies-1.png";
import Bodies2 from "./murder-ballad/process/bodies-2.png";
import Bodies3 from "./murder-ballad/process/bodies-3.png";

import Shoot1 from "./murder-ballad/process/photoshoot/1.JPG";
import Shoot2 from "./murder-ballad/process/photoshoot/2.JPG";
import Shoot3 from "./murder-ballad/process/photoshoot/3.JPG";
import Shoot4 from "./murder-ballad/process/photoshoot/4.JPG";
import Shoot5 from "./murder-ballad/process/photoshoot/5.JPG";
import Shoot6 from "./murder-ballad/process/photoshoot/6.JPG";
import Shoot7 from "./murder-ballad/process/photoshoot/7.JPG";
import Shoot8 from "./murder-ballad/process/photoshoot/8.JPG";
import Shoot9 from "./murder-ballad/process/photoshoot/9.JPG";

import Shirt1 from "./murder-ballad/process/shirt-1.png";
import Shirt2 from "./murder-ballad/process/shirt-2.png";
import Shirt3 from "./murder-ballad/process/shirt-3.png";

import ImageResearch from "./murder-ballad/process/image-research.png";

import Profile1 from "./murder-ballad/profiles/1.jpg";
import Profile2 from "./murder-ballad/profiles/2.jpg";
import Profile3 from "./murder-ballad/profiles/3.jpg";
import Profile4 from "./murder-ballad/profiles/4.jpg";
import Profile5 from "./murder-ballad/profiles/5.jpg";
import Profile6 from "./murder-ballad/profiles/6.jpg";

// import FacebookCover from './murder-ballad/facebook-cover.png'
import MurderballadizerDemo from "./murder-ballad/murderballadizer-demo.mp4";
import BookletVideo from "./murder-ballad/booklet_edited.mov";

export default class MurderBallad {
  static slug = "murder-ballad";
  static title = "Murder Ballad";

  static writeup = (
    <ProjectWriteup
      title={MurderBallad.title}
      links={[
        ["http://profiles.snsmurder.com", "profiles.snsmurder.com"],
        [
          "https://github.com/ScotchLabs/Murderballadizer",
          "Murderballadizer (source code)"
        ]
      ]}
      team={<a href="https://berniceyu.com/">Bernice Yu</a>}
    >
      <p>
        Branding and publicity design for Scotch 'n' Soda Theater's 2016
        production of Murder Ballad, a one-act rock musical.
      </p>
      <div className="ProjectWriteup-title">Process</div>
      <p>
        Murder Ballad's{" "}
        <a href="http://www.mtishows.com/murder-ballad">official branding</a>{" "}
        has a retro, 8-bit vibe. We thought this would send mixed messages at a
        technical school like Carnegie Mellon. Our goal was to evoke the core
        themes of the musical: lust, passion, and ultimately muder. We were
        interested in using close-up shots of the human form to suggest passion
        &mdash; simultaneously and ambiguously violent and sexual.
      </p>
      <div className="ProjectWriteup-subtitle">Image research</div>
      <Gallery
        images={[ImageResearch]}
        caption="we searched the internet for inspiration"
      />
      <div className="ProjectWriteup-subtitle">Photoshoot</div>
      <Gallery images={[Shoot1, Shoot2, Shoot3]} />
      <Gallery images={[Shoot4, Shoot5, Shoot6]} style={{ marginTop: -10 }} />
      <Gallery
        images={[Shoot7, Shoot8, Shoot9]}
        style={{ marginTop: -10 }}
        caption="two of the show's performers volunteered to pose for us"
      />
      {/* <Gallery images={ [Shoot1, Shoot2, Shoot3, Shoot4, Shoot5, Shoot6, Shoot7, Shoot8, Shoot9, Shoot10, Shoot11, Shoot12] } caption="we searched the internet for inspiration"/> */}
      <div className="ProjectWriteup-subtitle">
        Body kaleidoscope explorations
      </div>
      <Gallery
        images={[Bodies1, Bodies2, Bodies3]}
        caption="created by tiling and rotating photos from our shoot"
      />
      <div className="ProjectWriteup-subtitle">T-shirt iterations</div>
      <Gallery images={[Shirt1, Shirt2, Shirt3]} />

      <div className="ProjectWriteup-title" style={{ marginTop: 50 }}>
        Social media
      </div>
      <p>
        We set out to rethink traditional social media marketing. In addition to
        Snapchat filters, we created the "Murderballadizer". This website turned
        the Facebook profile pictures of the cast and crew into provocative
        advertisements for the show.
      </p>
      <div className="ProjectWriteup-subtitle" style={{ marginBottom: 15 }}>
        Murderballadizer™
      </div>
      <video width="100%" controls>
        <source src={MurderballadizerDemo} type="video/mp4" />
      </video>

      <div className="ProjectWriteup-subtitle">Facebook profile pictures</div>
      <Gallery images={[Profile1, Profile2, Profile3]} />
      <Gallery
        images={[Profile4, Profile5, Profile6]}
        style={{ marginTop: -10 }}
        caption="the cast and crew used their facebook profiles to advertise"
      />

      <div className="ProjectWriteup-subtitle">Snapchat filters</div>
      <Gallery
        images={[Snapchat1, Snapchat2]}
        caption="we purchased a Snapchat filter around the University Center theater"
      />

      <div className="ProjectWriteup-title">Final print design</div>
      <p>
        After much iteration and final budget and content approval from the
        board, we put our final designs into production.
      </p>
      <div className="ProjectWriteup-subtitle">Teaser posters</div>
      <Gallery
        images={[TeaserA, TeaserB]}
        caption="identical except for the song lyric towards the top"
      />
      <div className="ProjectWriteup-subtitle">Main poster</div>
      <Gallery images={[Poster]} style={{ maxWidth: "85%" }} />
      <div className="ProjectWriteup-subtitle">
        T-shirt design for cast and crew
      </div>
      <Gallery images={[ShirtFront, ShirtBack]} />
      <div className="ProjectWriteup-subtitle">Playbill</div>
      <video width="360" height="640" autoPlay loop muted>
        <source src={BookletVideo} type="video/mp4" />
      </video>
    </ProjectWriteup>
  );

  static preview = (
    <ProjectPreview
      title={MurderBallad.title}
      slug={MurderBallad.slug}
      thumbnail={Thumbnail}
    >
      <p>Publicity and branding for a rock musical.</p>
      <p>
        Scotch 'n' Soda Theater's 2016 production of<br /> <i>Murder Ballad</i>{" "}
        at Carnegie Mellon University.
      </p>
    </ProjectPreview>
  );
}
