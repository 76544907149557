import React, { Component } from "react";

import "./Projects.css";
import Footer from "./Footer";

import * as AllProjects from "./AllProjects";
//@ts-ignore
const projects = Object.keys(AllProjects).map(key => AllProjects[key]);

class Projects extends Component {
  boundHandleScroll!: () => any;

  componentDidMount() {
    this.fadeProjectsIn();
    this.boundHandleScroll = this.handleScroll.bind(this);
    window.addEventListener("scroll", this.boundHandleScroll);
  }

  componentDidUpdate(nextProps: { children?: any }) {
    if (nextProps.children) {
      this.fadeProjectsIn();
    }
  }

  handleScroll() {
    if (window.pageYOffset > 100) {
      this.fadeProjectsIn(0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.boundHandleScroll);
  }

  fadeProjectsIn(delay = 600) {
    const projects = Array.from(document.querySelectorAll<HTMLElement>(".ProjectPreview"));
    projects.forEach((project, i) => {
      // eslint-disable-next-line
      setTimeout(() => (project.style.opacity = '1'), delay * i);
    });
  }

  render() {
    // a specific project route is active
    if (this.props.children) {
      return this.props.children;
    }
    return (
      <div className="Projects">
        {projects.map(project =>
          React.cloneElement(project.preview, { key: project.slug })
        )}
        <Footer />
      </div>
    );
  }
}

export default Projects;
