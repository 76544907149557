import React from "react";

import ProjectWriteup from "../ProjectWriteup";
import ProjectPreview from "../ProjectPreview";
import Gallery from "../Gallery";

import Thumbnail from "./intaxicated/thumbnail@2x.png";
import Battleship from "./intaxicated/battleship.png";
import ISpy0 from "./intaxicated/ispy-0.jpg";
import ISpy1 from "./intaxicated/ispy-1.png";
import ISpy2 from "./intaxicated/ispy-2.png";
import ISpy3 from "./intaxicated/ispy-3.png";

import ISpyVideo from "./intaxicated/ispy.mp4";
import StumblingVideo from "./intaxicated/stumbling.mp4";

import EarlyVersion1 from "./intaxicated/early-version-1.png";
import EarlyVersion2 from "./intaxicated/early-version-2.png";
import EarlyVersion3 from "./intaxicated/early-version-3.png";
import FinalPlaytesting from "./intaxicated/final-playtesting.jpg";
import Final1 from "./intaxicated/final-1.png";
import Final2 from "./intaxicated/final-2.png";
import Final3 from "./intaxicated/final-3.png";

export default class Intaxicated {
  static slug = "intaxicated";
  static title = "inTAXIcated";

  static writeup = (
    <ProjectWriteup
      title={Intaxicated.title}
      links={[
        ["https://intaxicated.herokuapp.com/", "Play online"],
        ["https://github.com/aroman/tunnel-vision", "source code"]
      ]}
      team={"Bryan Tiggs, Ariana Daly, Giada Sun, Kate Chaudoin"}
    >
      <p>
        Intaxicated is a two player game in which a drunkard &mdash; too
        intoxicated to make it home on their own &mdash; has called a Brewber to
        safely take them home from the bars. However, finding a drunk person in
        this spaghetti road town is like trying to catch a cat. GPS isn't
        working so the Brüber driver is on the phone with their drunkard trying
        to coordinate the pickup location. The drunkard is stumbling around
        town, trying their best to direct their driver to them before they black
        out.
      </p>
      <div className="ProjectWriteup-subtitle">Video</div>
      <div className="ProjectWriteup-embedContainer">
        <iframe
          title="Intaxicated Trailer Video"
          allowFullScreen={true}
          src="https://player.vimeo.com/video/238063544?wmode=opaque&amp;api=1"
        />
      </div>
      <div className="ProjectWriteup-subtitle">Inspiration: Battleship</div>
      <p>
        This project was created for{" "}
        <a href="https://twitter.com/molleindustria">Paolo Pedercini's</a>{" "}
        <a href="http://mycours.es/gamedesign2017/">
          Experimental Game Design 2017
        </a>, at Carnegie Mellon University in. This assignment was about
        "updating the classics" &mdash; Monopoly, Battleship, CandyLand, etc.
        Our team was assigned the game Battleship, and told to "fix it" by
        adding a narrative play style. After much research, prototyping, and
        playtesting, our team came up with inTAXIcated.{" "}
      </p>
      <Gallery
        images={[Battleship]}
        caption="original game packaging for Battleship"
      />
      <div className="ProjectWriteup-embedContainer">
        <video width="360" height="640" controls>
          <source src=".mp4" type="video/mp4" />
        </video>
        <iframe
          title="1967/1968 Battleship Board Game Commercial"
          src="https://www.youtube.com/embed/PrHs8CWDzmc?theme=light"
          frameBorder={"0"}
          allowFullScreen
        />
      </div>
      <div className="ProjectWriteup-subtitle">
        Early Prototyping: DIY "I-SPY"
      </div>
      <p>
        Our initial prototypes explored the concept of two players trying to
        find eachother, given only limited information about the game map. We
        created a paper cut-out overlay (think a door peephole), allowing only a
        1" square of the underlying paper to be seen at once. We then designed
        and printed out various images, overlaid with 1" grids, and playtested
        variations of a turn-based where two players tried to marco-polo their
        way to the same location, without looking at eachother's map.
      </p>
      <Gallery images={[ISpy0]} caption="early playesting maps" />
      <Gallery
        images={[ISpy1, ISpy2, ISpy3]}
        caption="we made a simple chess timer website to structure turn taking"
      />
      <video width="100%" controls>
        <source src={ISpyVideo} type="video/mp4" />
      </video>
      <div className="ProjectWriteup-subtitle">Iterating and playtesting</div>
      <p>
        Eventually, we decided to move to a digital medium, and theme the game
        around the concept of "drunk ride sharing". We spent a considerable
        amount of time tweaking the map and adding and balancing constraints for
        both players.
      </p>
      <Gallery images={[EarlyVersion3]} />
      <Gallery
        nudgeUp
        images={[EarlyVersion1]}
        caption="early prototypes of the driver UI"
      />
      <Gallery
        images={[EarlyVersion2]}
        caption="initial development of the map"
      />
      <div className="ProjectWriteup-subtitle">Final touches</div>
      <p>
        We made use of blurring and animation to simulate the effect of being
        drunk. We also adopted a familiar branding design.
      </p>
      <video width="100%" autoPlay loop muted>
        <source src={StumblingVideo} type="video/mp4" />
      </video>
      <Gallery
        nudgeUp
        images={[Final1]}
        caption="totally original branding design"
      />
      <Gallery
        nudgeUp
        images={[Final2, Final3]}
        caption="Adding rules screens"
      />
      <Gallery
        images={[FinalPlaytesting]}
        caption="Paolo and Aman playing the final version of our game"
      />
    </ProjectWriteup>
  );

  static preview = (
    <ProjectPreview
      title={Intaxicated.title}
      slug={Intaxicated.slug}
      thumbnail={Thumbnail}
    >
      <p>Drunk ride sharing simulator.</p>
      <p>
        Stumble and shout your way to the pickup point as drunkard or Brüber
        Driver.
      </p>
    </ProjectPreview>
  );
}
