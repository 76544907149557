import React from "react";

import ProjectWriteup from "../ProjectWriteup";
import ProjectPreview from "../ProjectPreview";
import Gallery from "../Gallery";

import Thumbnail from "./keeba/thumbnail.png";
import Screenshot1 from "./keeba/keeba-screenshot-1.png";
import Screenshot2 from "./keeba/keeba-screenshot-2.png";

export default class Keeba {
  static slug = "keeba";
  static title = "Keeba";

  static writeup = (
    <ProjectWriteup
      title={Keeba.title}
      links={[
        [
          "https://web.archive.org/web/20160322014329/http://keeba.jbha.org/",
          "keeba.jbha.org (via Wayback Machine)"
        ],
        ["https://github.com/aroman/keeba", "GitHub Project"],
        ["http://keeba-blog.tumblr.com", "The Keeba Blog"]
      ]}
    >
      <p>
        My high school’s homework website left much to be desired in terms of
        usability, functionality, and intuitiveness. To address these problems
        without requiring teacher re-training or other involvement from the
        school’s administration, as a sophomore in high school, I
        reverse-engineered the existing homework website and provided an
        alternative interface to access. It grew quickly from a simple set of
        parsing algorithms and an HTTP proxy API for my personal use to a
        state-of-the-art personal productivity web app with wide adoption by my
        school community.
      </p>
      <div className="ProjectWriteup-subtitle">Promo video</div>
      <div className="ProjectWriteup-embedContainer">
        <iframe
          title="Promo video"
          src="https://www.youtube.com/embed/4fu26I4W9MY?theme=light"
          frameBorder={"0"}
          allowFullScreen
        />
      </div>
      <div className="ProjectWriteup-subtitle">Screenshots</div>
      <Gallery nudgeUp images={[Screenshot1]} caption="project homepage" />
      <Gallery
        nudgeUp
        images={[Screenshot2]}
        caption="sign in with @jbha.org email"
      />
      <div className="ProjectWriteup-subtitle">Architecture</div>
      <p>
        Keeba is a client-heavy single-page style web app which makes heavy use
        of JavaScript and CoffeeScript. The client embraces the modern
        JavaScript MVC pattern (through Backbone) and the server is a highly
        modularized set of internal and external APIs. Keeba uses makes heavy
        use of UNIX IPC and process forking to maintain a high degree of
        concurrency and avoid clogging the eventloop.
      </p>
    </ProjectWriteup>
  );

  static preview = (
    <ProjectPreview title={Keeba.title} slug={Keeba.slug} thumbnail={Thumbnail}>
      <p>Make homework fun again.</p>
      <p>
        Sign in with your existing school account. View and track your homework
        in a beautiful online interface.
      </p>
    </ProjectPreview>
  );
}
