import React from 'react'

import ProjectWriteup from '../ProjectWriteup'
import ProjectPreview from '../ProjectPreview'
import Gallery from '../Gallery'

import Thumbnail from './tapestry/thumbnail.png'
import PosterPreview from './tapestry/poster-preview.png'

export default class Tapestry {

  static slug = 'tapestry'
  static title = 'Tapestry'

  static writeup = (
    <ProjectWriteup
      title={Tapestry.title}
      links={[
        ['https://www.dropbox.com/s/tmbua1qlt34bf42/tapestry-poster.pdf?dl=0', 'Research conference poster'],
      ]}
      team="Tiffany Jiang, Nikhil Choudhary">
      <p>
        Presented at Carnegie Mellon's Undergraduate Research Symposium
      </p>
      <div className="ProjectWriteup-subtitle">Poster</div>
      <a href="https://www.dropbox.com/s/tmbua1qlt34bf42/tapestry-poster.pdf?dl=0" style={{ textDecoration: 'none' }}>
        <Gallery hasBorder images={[PosterPreview]} caption="click for PDF version" />
      </a>
      <div className="ProjectWriteup-subtitle">Abstract</div>
      <p>
        <em>
          Automatically Mining Social Media for Videos with High Temporal & Spatial Locality for Use in 3D Reconstruction
        </em>
      </p>
    </ProjectWriteup>
  )

  static preview = (
    <ProjectPreview title={Tapestry.title} slug={Tapestry.slug} thumbnail={Thumbnail}>
      <p>YouTube search for computer vision experts.</p>
      <p style={{ fontStyle: 'italic', fontSize: '15px' }}>Automatically Mining Social Media for Videos with High Temporal & Spatial Locality for Use in 3D Reconstruction</p>
    </ProjectPreview>
  )

}
