import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./ProjectWriteup.css";

import { Trophy, Team, Link as LinkIcon } from "./Icons";

type ProjectWriteupProps = {
    title: string,
    trophy: boolean,
    links: Array<[string, string]>,
    team: string,
    prevProject: any,
    nextProject: any,
}

class ProjectWriteup extends Component<ProjectWriteupProps> {
    boundHandleScroll!: () => any;
    self: HTMLDivElement | null = null;

    componentDidMount() {
        this.boundHandleScroll = this.handleScroll.bind(this);
        window.addEventListener("scroll", this.boundHandleScroll);
        document.querySelector(".Header")!.classList.add("compact");
        setTimeout(() => this.fadeChildrenIn(false), 0);
        window.document.title = `${this.props.title} - Avi Romanoff`;
    }

    handleScroll() {
        if (window.pageYOffset > 10) {
            this.fadeChildrenIn(true);
        }
    }

    componentWillUnmount() {
        window.document.querySelector(".Header")!.classList.remove("compact");
        window.removeEventListener("scroll", this.boundHandleScroll);
        window.document.title = `Avi Romanoff`;
    }

    fadeChildrenIn(immediately: boolean) {
        // XXX: Why is this.self sometimes null here?
        if (!this.self) {
            return;
        }
        const delays = [0, 800, 1300];
        Array.from(this.self.children).forEach((child, i) => {
            const delay = immediately ? 0 : delays[i];
            setTimeout(() => ((child as HTMLElement).style.opacity = '1'), delay);
        });
    }

    render() {
        const { title, trophy, links, team, prevProject, nextProject } = this.props;
        return (
            <div className="ProjectWriteup" ref={el => (this.self = el)}>
                <div className="ProjectWriteup-title">{title}</div>
                <div className="ProjectWriteup-summary">
                    {!trophy ? null : (
                        <div>
                            <img
                                className="ProjectWriteup-summary-icon"
                                src={Trophy}
                                alt=""
                            />
                            {trophy}
                        </div>
                    )}
                    {!links
                        ? null
                        : links.map(([href, text]) => (
                            <div key={href}>
                                <img
                                    className="ProjectWriteup-summary-icon"
                                    src={LinkIcon}
                                    alt=""
                                />
                                <a href={href}>{text}</a>
                            </div>
                        ))}
                    {!team ? null : (
                        <div>
                            <img
                                className="ProjectWriteup-summary-icon"
                                src={Team}
                                alt=""
                            />
                            {team}
                        </div>
                    )}
                </div>
                <div className="ProjectWriteup-content">
                    {this.props.children}
                    <div className="ProjectWriteup-bottomNav">
                        {!prevProject ? null : (
                            <div className="ProjectWriteup-back">
                                <Link to={`/projects/${prevProject.slug}`}>{`<- ${
                                    prevProject.title
                                    }`}</Link>
                            </div>
                        )}
                        {!nextProject ? null : (
                            <div className="ProjectWriteup-forward">
                                <Link to={`/projects/${nextProject.slug}`}>{`${
                                    nextProject.title
                                    } ->`}</Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectWriteup;
